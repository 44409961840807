import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Loading from "../../Components/Loading";
import Seo from "../../Components/Seo";
import YapForm from "./YapForm";

// let inboundDatesData = [
//     {
//         title: 'India South Tour',
//         arrival: 'Hyderabad',
//         departure: 'Bangalore',
//         countries: 'India',
//         dates: '16.07.2023 to 06.08.2023'
//     },
//     {
//         title: 'India North Tour',
//         arrival: 'Mumbai',
//         departure: 'Delhi',
//         countries: 'India',
//         dates: '23.07.2023 to TBD'
//     },
// ]

let inboundDatesData = [
    {
        title: 'India South Tour',
        arrival: 'Chennai',
        departure: 'Hyderabad',
        countries: 'India',
        dates: '14.07.24 to 04.08.24'
    },
    {
        title: 'India North Tour',
        arrival: 'Kolkata',
        departure: 'New Delhi',
        countries: 'India',
        dates: '21.07.24 to 11.08.24'
    },
]

let outgoingDatesData = [
    {
        title: 'Europe South Tour',
        arrival: 'Milan',
        departure: 'Brussels',
        countries: 'Italy, Germany, Austria & Belgium',
        dates: '09.06.24 to 30.06.24'
    },
    {
        title: 'Europe North Tour',
        arrival: 'Frankfurt',
        departure: 'Helsinki',
        countries: 'Germany, Denmark & Finland',
        dates: '09.06.24 to 30.06.24'
    },
    {
        title: 'Great Britain & Ireland',
        arrival: 'Edinburgh',
        departure: 'London Heathrow',
        countries: 'UK & Ireland',
        dates: '30.06.24 to 14.07.24'
    },
    {
        title: 'South Africa Tour',
        arrival: 'Durban',
        departure: 'Johannesburg',
        countries: 'South Africa',
        dates: '23.08.24 to 08.09.24'
    }
]


// let outgoingDatesData = [
//     {
//         title: 'Europe South Tour',
//         arrival: 'Brussels',
//         departure: 'Milan',
//         countries: 'Belgium, Germany,Austria and Italy',
//         dates: '11.06.2023 to 02.07.2023'
//     },
//     {
//         title: 'Europe North Tour',
//         arrival: 'Berlin',
//         departure: 'TBA',
//         countries: 'Germany, Denmark and Finland',
//         dates: '12.06.2023 to 03.07.2023'
//     },
//     {
//         title: 'Europe Mid Tour',
//         arrival: 'Amsterdam',
//         departure: 'Zurich',
//         countries: 'Netherlands,Belgium,Germany and Switzerland',
//         dates: '22.06.2023 to 09.07.2023'
//     },
//     {
//         title: 'South Africa Tour',
//         arrival: 'Port Elizabeth',
//         departure: 'East London',
//         countries: 'Not confirmed',
//         dates: '19.08.2023 – 03.09.2023'
//     },
//     {
//         title: 'Great Britain & Ireland',
//         arrival: 'London Heathrow',
//         departure: 'London Heathrow',
//         countries: 'Not confirmed',
//         dates: '16.07.2023 to 30.07.2023'
//     }
// ]

export default class Yap extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'Young Ambassador Program | 41 India'}
                     description={'Learn more about the Young Ambassador Program of 41 India, apply and be an Ambassador for a leading Service Organization'}
                     slug={'/programs/yap'} />
                <Container>
                    <h1>Young Ambassador Program (YAPs)</h1>
                    <Tabs defaultActiveKey="info">
                        <Tab eventKey="info" title="About">
                            <div className='text-center'>
                                <img src='https://assets.41clubsofindia.org/yaps-logo.jpeg' width={'30%'}/>
                            </div>
                            <p>
                                The Young Ambassador Program more popularly known as ‘YAPs’ is a flagship activity of 41
                                International for the children of the members of 41 clubs all over the world. YAP seeks
                                to promote exchange visits between children groups of India, Europe and other countries.
                                This program gives a great opportunity to the young adults to meet people of various
                                ethnicity, learn foreign cultures and see new places, all of which are a huge learning
                                experience for them.
                            </p>
                            <p>
                                The YAP seeks to promote exchange visits between the children of parents who are members
                                of 41 Clubs.
                            </p>
                            <p>
                                Objectives of these visits are the following:
                            </p>
                            <ol>
                                <li>Exposing children to different cultures, lifestyles and histories</li>
                                <li>Building friendships and close relationships across continents</li>
                                <li>Developing confidence, trust and international understanding</li>
                            </ol>
                            <p>
                                Requirements to be an ambassador:
                            </p>
                            <ol>
                                <li>You must be between 18 and 25 years of age</li>
                                <li>You have a working knowledge of English and are able to express yourself in the
                                    language of the host country.
                                </li>
                                <li>You are prepared to travel along wherever required.</li>
                            </ol>
                        </Tab>
                        <Tab eventKey="inbound" title="Inbound YAP 2024">
                            <Dates datesData={inboundDatesData}/>
                        </Tab>
                        <Tab eventKey="registration" title="Registration">
                            <YapForm datesData={outgoingDatesData}/>
                        </Tab>
                        <Tab eventKey="outbound" title="Outbound YAP 2024">
                            <Dates datesData={outgoingDatesData}/>
                        </Tab>
                    </Tabs>
                </Container>
            </div>
        );
    }
}

class Dates extends React.Component {
    render() {
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Program</th>
                        <th>Arrival City</th>
                        <th>Ðeparture City</th>
                        <th>Countries</th>
                        <th>Dates</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.datesData.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{item.title}</td>
                                <td>{item.arrival}</td>
                                <td>{item.departure}</td>
                                <td>{item.countries}</td>
                                <td>{item.dates}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>

            </div>
        );
    }
}

class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            passport: '',
            dob: '',
            nationality: '',
            gender: '',
            motherTongue: '',
            mobile: '',
            email: '',
            address: '',
            profession: '',
            fatherName: '',
            fatherMobile: '',
            fatherClub: '',
            fatherEmail: '',
            educationHistory: '',
            finalDegree: '',
            travelledWithYap: '',
            listOfCountriesTravelled: '',
            sportsActivities: '',
            medicalInfo: '',
            smoker: '',
            drinker: '',
            vegetarian: '',
            additionalInfo: '',
            toc: false,
            validated: false,
            mobileValid: false,
            utr: '',
            bank: '',
            tour: ''
        }
        this.changeState = this.changeState.bind(this);
        this.changeChecked = this.changeChecked.bind(this);
        this.uploadPassport = this.uploadPassport.bind(this);
        this.checkValidity = this.checkValidity.bind(this);
        this.submit = this.submit.bind(this);
    }

    changeState(e) {
        let mobileValid = this.state.mobileValid;
        if (e.target.name === 'mobile') {
            mobileValid = e.target.value.length === 10;
        }
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            mobileValid: mobileValid
        })
    }

    changeChecked(e) {
        // TODO: Make this.state.toc hold the timestamp of when it was agreed to
        this.setState({
            ...this.state,
            [e.target.name]: !this.state[e.target.name]
        })
    }

    uploadPassport(e) {
        this.setState({
            ...this.state,
            file: e.target.files[0],
            files: e.target.files
        })
    }

    checkValidity() {

    }

    submit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        // console.log(this.checkValidity())

        if (this.checkValidity() === false) {
            // console.log('this')
            return;
        }

        this.setState({
            ...this.state,
            validated: true,
            submissionInProgress: true
        })

        // let file = this.state.file;
        // let fileName = 'yap-submission-' + Date.now() + '.pdf';
        // fetch('https://b1tpmz82h0.execute-api.ap-south-1.amazonaws.com/yap-passport-upload?contentType=' + file.type + '&fileName=' + fileName)
        //     .then(resp => resp.text())
        //     .then(uploadUrl => {
        //         console.log(uploadUrl)
        //         return fetch(uploadUrl, {
        //             method: 'PUT',
        //             // headers: new Headers({ 'Content-Type': file.type}),
        //             body: file
        //         })
        //             .then(response => response.text())
        //             .then(response => {
        //                 return fileName
        //             })
        //     })


        // // Step 1: Upload the Passport
        // // Step 2: Submit Data with passport info
        // fetch('https://b1tpmz82h0.execute-api.ap-south-1.amazonaws.com/yap-submission', {
        //     method: 'POST',
        //     body: JSON.stringify(this.state)
        // })
        //     .then(resp => resp.text())
        //     .then(resp => console.log(resp))


        // console.log(JSON.stringify(this.state))
    }

    render() {
        return (
            <Container>
                {this.state.submissionInProgress && <Loading/>}
                <Form noValidate validated={this.state.validated} onSubmit={this.submit}>
                    <Row><h3>Personal Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter first name"
                                              value={this.state.firstName} onChange={this.changeState}
                                              name='firstName'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter last name"
                                              value={this.state.lastName} onChange={this.changeState} name='lastName'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="dob">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control required type="date" value={this.state.dob} onChange={this.changeState}
                                              name='dob'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="passport">
                                <Form.Label>Passport Number</Form.Label>
                                <Form.Control required type="text" placeholder="Enter passport number"
                                              value={this.state.passport} onChange={this.changeState} name='passport'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="nationality">
                                <Form.Label>Nationality</Form.Label>
                                <Form.Control required type="text" placeholder="Enter nationality"
                                              value={this.state.nationality} onChange={this.changeState}
                                              name='nationality'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="gender">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control required type="text" placeholder="Enter gender" value={this.state.gender}
                                              onChange={this.changeState} name='gender'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="motherTongue">
                                <Form.Label>Mother Tongue</Form.Label>
                                <Form.Control required type="text" placeholder="Enter mother tongue"
                                              value={this.state.motherTongue} onChange={this.changeState}
                                              name='motherTongue'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="mobile">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control required type="number" placeholder="Enter mobile"
                                              value={this.state.mobile} onChange={this.changeState} name='mobile'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="email" placeholder="Enter email" value={this.state.email}
                                              onChange={this.changeState} name='email'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="profession">
                                <Form.Label>Profession</Form.Label>
                                <Form.Control required type="text" placeholder="Enter profession"
                                              value={this.state.profession} onChange={this.changeState}
                                              name='profession'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control required as="textarea" rows={3} placeholder="Enter address"
                                              value={this.state.address} onChange={this.changeState} name='address'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row><h3>Father's Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="fatherName">
                                <Form.Label>Father's Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter father's name"
                                              value={this.state.fatherName} onChange={this.changeState}
                                              name='fatherName'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="fatherClub">
                                <Form.Label>Father's Club</Form.Label>
                                <Form.Control required type="text" placeholder="Enter father's club"
                                              value={this.state.fatherClub} onChange={this.changeState}
                                              name='fatherClub'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="fatherMobile">
                                <Form.Label>Father's Mobile</Form.Label>
                                <Form.Control required type="number" placeholder="Enter father's mobile"
                                              value={this.state.fatherMobile} onChange={this.changeState}
                                              name='fatherMobile'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="fatherEmail">
                                <Form.Label>Father's Email</Form.Label>
                                <Form.Control required type="email" placeholder="Enter father's email"
                                              value={this.state.fatherEmail} onChange={this.changeState}
                                              name='fatherEmail'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row><h3>Educational Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="educationHistory">
                                <Form.Label>Educational History</Form.Label>
                                <Form.Control required as="textarea" rows={3} placeholder="Enter educational history"
                                              value={this.state.educationHistory} onChange={this.changeState}
                                              name='educationHistory'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="finalDegree">
                                <Form.Label>Final Degree</Form.Label>
                                <Form.Control required as="textarea" rows={3} placeholder="Enter final degree"
                                              value={this.state.finalDegree} onChange={this.changeState}
                                              name='finalDegree'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row><h3>Other Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="travelledWithYap">
                                <Form.Label>Have you ever travelled on a YAPS tour?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='travelledWithYap'
                                             value={this.state.travelledWithYap}>
                                    {/*<option>Select your answer</option>*/}
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="listOfCountriesTravelled">
                                <Form.Label>Which countries have you travelled to? (with and without YAPS)</Form.Label>
                                <Form.Control required type="text" placeholder="Which countries have you travelled to?"
                                              value={this.state.listOfCountriesTravelled} onChange={this.changeState}
                                              name='listOfCountriesTravelled'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="listOfCountriesTravelled">
                                <Form.Label>Which YAP Tour are you interested in?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='smoker'
                                             value={this.state.smoker}>
                                    {this.props.datesData.map((dates, idx) => {
                                        return (
                                            <option value={dates.title} key={idx}>{dates.title}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="sportsActivities">
                                <Form.Label>Do you participate in any sports or other activities?</Form.Label>
                                <Form.Control required as="textarea" rows={3}
                                              placeholder="Do you participate in any sports or other activities?"
                                              value={this.state.sportsActivities} onChange={this.changeState}
                                              name='sportsActivities'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="medicalInfo">
                                <Form.Label>Do you have any diseases, allergies or medical information you wish to
                                    inform us about?</Form.Label>
                                <Form.Control required as="textarea" rows={3}
                                              placeholder="Do you have any diseases, allergies or medical information you wish to inform us about?"
                                              value={this.state.medicalInfo} onChange={this.changeState}
                                              name='medicalInfo'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="smoker">
                                <Form.Label>Are you a smoker?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='smoker'
                                             value={this.state.smoker}>
                                    {/*<option>Select your answer</option>*/}
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="drinker">
                                <Form.Label>Are you a drinker?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='drinker'
                                             value={this.state.drinker}>
                                    {/*<option>Select your answer</option>*/}
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="vegetarian">
                                <Form.Label>Are you a vegetarian?</Form.Label>
                                <Form.Select required onChange={this.changeState} name='vegetarian'
                                             value={this.state.vegetarian}>
                                    {/*<option>Select your answer</option>*/}
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="additionalInfo">
                                <Form.Label>Is there any additional information you wish to provide?</Form.Label>
                                <Form.Control as="textarea" rows={3}
                                              placeholder="Is there any additional information you wish to provide?"
                                              value={this.state.additionalInfo} onChange={this.changeState}
                                              name='additionalInfo'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="passportUpload">
                                <Form.Label>Please upload a copy of your passport</Form.Label>
                                <Form.Control required type="file" onChange={this.uploadPassport} name='passportUpload'
                                              accept='.pdf'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row><h3>Payment Information</h3></Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="utr">
                                <Form.Label>Please provide the UTR of your payment</Form.Label>
                                <Form.Control required type="text" placeholder="Enter transaction UTR"
                                              value={this.state.utr} onChange={this.changeState}
                                              name='utr'/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="utr">
                                <Form.Label>Please provide the Bank from which your payment was made</Form.Label>
                                <Form.Control required type="text" placeholder="Enter bank from which payment was made"
                                              value={this.state.bank} onChange={this.changeState}
                                              name='bank'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <p>
                            <strong>Disclaimer</strong>
                        </p>
                        <ul>
                            <li>
                                So declare that I accept to participate in the YAP with the conditions so arranged
                                between the
                                "YAP / Famex Austauschverein e.V." and the sponsoring national association.
                            </li>
                            <li>
                                I shall make all arrangements such as return flight tickets.
                            </li>
                            <li>
                                I waive my rights to make any claims against the YAP/Famex –Austauschverein e.V., 41
                                International and the sponsoring Associations for misrepresentation and for damages of
                                any
                                nature either or that I might encounter during my participation of this program.
                            </li>
                            <li>
                                I shall take an insurance policy before my departure and for the duration of the program
                                to
                                cover myself against sickness and accident, in addition I shall take cover for Civil
                                liability for
                                material or Corporal damages incurred by myself.
                            </li>
                            <li>
                                I shall not indulge in drugs or any illicit activities. I understand that this would
                                result in
                                immediate deportation.
                            </li>
                            <li>
                                I understand that our family would be obligated to host inbound Yaps in the future .
                            </li>
                        </ul>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="toc">
                                <Form.Check required type="checkbox" name='toc' label="I agree to the terms"
                                            onClick={this.changeChecked}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    {this.state.submissionInProgress && <Loading/>}
                    <Row>
                        <Col md>
                            <Button variant="primary" onClick={this.submit}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }
}