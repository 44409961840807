import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Seo from "../../Components/Seo";

export default class NationalBoard extends React.Component {
    render() {
        return (
            <div>
                <Seo title={'National Board | 41 India'}
                     description={'Learn about the National Board and Leadership of 41 India, the pan India service organization.'}
                     slug={'/about/national-board'} />
                <div style={{
                    minHeight: '300px',
                    position: 'relative',
                    background: 'url("https://assets.41clubsofindia.org/hero/national-board.jpeg")',
                    width: '100%',
                    margin: 'auto',
                    backgroundSize: 'cover'
                }}></div>
                <Container>
                    <Row>
                        <h1>National Board of 41 India</h1>
                    </Row>
                    <Row>
                        {boardMembers.map((boardMember, idx) => {
                            return (
                                <Col md={3} sm={4} key={idx}>
                                    <div>
                                        <img src={boardMember['image']} style={{
                                            width: '100%',
                                            height: 'auto',
                                            aspectRatio: '1',
                                            objectFit: 'scale-down'
                                        }}/>
                                    </div>
                                    <div className='text-center'>
                                        <h4>{boardMember['name']}</h4>
                                        <p>
                                            {boardMember['designation']}<br/>
                                            <strong>Club:</strong> {boardMember['club']} | <strong>Area:</strong> {boardMember['area']}<br/>
                                            {boardMember['phone'] &&
                                            <span><strong>Phone:</strong> {boardMember['phone']}<br/></span>}
                                            {boardMember['email'] &&
                                            <span><strong>Email:</strong> {boardMember['email']}</span>}
                                        </p>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div>
        );
    }
}

const boardMembers = [
    {
        "designation": "President",
        "name": "Srinivasu Saraswatula",
        "area": 4,
        "club": 213,
        "email": "srinirti@gmail.com",
        "phone": "9820346931",
        image: 'https://assets.41clubsofindia.org/national-board/srini.jpg'
    },
    {
        "designation": "IPP",
        "name": "B.K.Singh Deo",
        "area": 6,
        "club": 37,
        "email": "bksinghdeo@gmail.com",
        "phone": "9337125371",
        image: 'https://assets.41clubsofindia.org/national-board/bk-singh-deo.jpg'
    },
    {
        "designation": "Vice President",
        "name": "Pankajj Vats",
        "area": 5,
        "club": "",
        "email": "",
        "phone": "",
        image: 'https://assets.41clubsofindia.org/national-board/pankaj.jpg'
    },
    {
        "designation": "Secretary",
        "name": "Bhupinder Singh Chandok (Pompy)",
        "area": 6,
        "club": "",
        "email": "",
        "phone": "",
        image: 'https://assets.41clubsofindia.org/national-board/bhupinder.jpg'
    },
    {
        "designation": "Treasurer",
        "name": "D. Madhu Babu",
        "area": 7,
        "club": "",
        "email": "",
        "phone": "",
        image: 'https://assets.41clubsofindia.org/national-board/madhu.jpg'
    },
    {
        "designation": "Editor",
        "name": "Sumit katariya",
        "area": 4,
        "club": "",
        "email": "",
        "phone": "",
        image: 'https://assets.41clubsofindia.org/national-board/sumeet.jpg'
    },
    {
        "designation": "IRO",
        "name": "Vivek Mata",
        "area": 3,
        "club": 89,
        "email": "vmata@charnock.biz",
        "phone": "9845014848",
        image: 'https://assets.41clubsofindia.org/national-board/vivek-mata.jpg'
    },
    {
        "designation": "Chairman Area 1",
        "name": "Chakravarthy S Birur",
        "area": 1,
        "club": 86,
        "email": "chakragold@yahoo.com",
        "phone": "9894050227",
        image: ''
    },
    {
        "designation": "Chairman Area 2",
        "name": "Ganesh Kini",
        "area": 2,
        "club": 7,
        "email": "tgkini@gmail.com",
        "phone": "9848126940",
        image: 'https://assets.41clubsofindia.org/national-board/ganesh-kini.jpg'
    },
    {
        "designation": "Chairman Area 3",
        "name": "A P Rajaram Gowda",
        "area": 3,
        "club": 248,
        "email": "aprajaramgowda@gmail.com",
        "phone": "9538845669",
        image: ''
    },
    {
        "designation": "Chairman Area 4",
        "name": "Kumar Ruparelia",
        "area": 4,
        "club": 80,
        "email": "kumar@kumargroup.co.in",
        "phone": "9820133503",
        image: 'https://assets.41clubsofindia.org/national-board/kumar.jpg'
    },
    {
        "designation": "Chairman Area 5",
        "name": "Sunil Kothari",
        "area": 5,
        "club": 239,
        "email": "Sk1401@gmail.com",
        "phone": "9829307733",
        image: 'https://assets.41clubsofindia.org/national-board/sunil-kothari.jpg'
    },
    {
        "designation": "Chairman Area 6",
        "name": "Gaurav Khemka",
        "area": 6,
        "club": 235,
        "email": "41gauravk@gmail.com",
        "phone": "9435557575",
        image: 'https://assets.41clubsofindia.org/national-board/gaurav.jpg'
    },
    {
        "designation": "Chairman Area 7",
        "name": "Sumeet Munglani",
        "area": 7,
        "club": 206,
        "email": "sumetmunglani@gmail.com",
        "phone": "9246530451",
        image: 'https://assets.41clubsofindia.org/national-board/sumeet-m.jpg'
    },
    {
        "designation": "Chairman Area 8",
        "name": "Varghese James Cheeran",
        "area": 8,
        "club": 208,
        "email": "Cheerans@hotmail.com",
        "phone": "9847030350",
        image: 'https://assets.41clubsofindia.org/national-board/varghese.jpg'
    },
    {
        "designation": "Chairman Area 9",
        "name": "Peeyush Agarwal",
        "area": 9,
        "club": 127,
        "email": "peeyush_area8@yahoo.com",
        "phone": "9837030798",
        image: 'https://assets.41clubsofindia.org/national-board/peeyush.jpg'
    },
    {
        "designation": "Chairman Area 10",
        "name": "Kiran Hebsur",
        "area": 10,
        "club": 124,
        "email": "Kbhebsur@gmail.com",
        "phone": "9972098121",
        image: 'https://assets.41clubsofindia.org/national-board/kiran.jpg'
    },
    {
        "designation": "Covenor Extension",
        "name": "Satya (Sakhamuri Venkat Satyanarayana )",
        "area": 7,
        "club": 150,
        "email": "satyanita@gmail.com",
        "phone": "9848020202",
        image: 'https://assets.41clubsofindia.org/national-board/sati.jpg'
    },
    {
        "designation": "Covenor FTDE",
        "name": "Anuurag Mehraa",
        "area": 4,
        "club": 104,
        "email": "41amehra@gmail.com",
        "phone": "9586763555",
        image: 'https://assets.41clubsofindia.org/national-board/anurag.jpg'
    },
    {
        "designation": "Covenor SYJ & Fund Raising",
        "name": "Yogesh Sheth",
        "area": 3,
        "club": 98,
        "email": "jaysilks@gmail.com",
        "phone": "9844113750",
        image: 'https://assets.41clubsofindia.org/national-board/yogesh.jpg'
    },
    {
        "designation": "Covenor Howzatt",
        "name": "Ruchir Gupta",
        "area": 4,
        "club": 197,
        "email": "ruchirgupta1973@gmail.com",
        "phone": "9726420888",
        image: 'https://assets.41clubsofindia.org/national-board/ruchir-gupta.jpg'
    },
    {
        "designation": "Covenor  YAPs (North)",
        "name": "Amit Kumar Saraff",
        "area": 5,
        "club": 273,
        "email": "amit_saraff@hotmail.com",
        "phone": "9990017744",
        image: 'https://assets.41clubsofindia.org/national-board/amit.jpg'
    },
    {
        "designation": "Covenor YAPs (South)",
        "name": "Kutub naseem",
        "area": 3,
        "club": 89,
        "email": "Kzn@krafts.co.in",
        "phone": "9845040123",
        image: 'https://assets.41clubsofindia.org/national-board/kutub.jpg'
    },
    {
        "designation": "Covenor NAGM",
        "name": "Bhasker Reddy",
        "area": 7,
        "club": 91,
        "email": "kbreddy6@gmail.com",
        "phone": "8008225362",
        image: 'https://assets.41clubsofindia.org/national-board/bhaskar.jpg'
    },
    {
        "designation": "Covenor Rules",
        "name": "Inder Anam",
        "area": 8,
        "club": 117,
        "email": "ainder@gmail.com",
        "phone": "9848020092",
        image: 'https://assets.41clubsofindia.org/national-board/inder.jpg'
    },
    {
        "designation": "Covenor Secretariat",
        "name": "Apoorva J Modi",
        "area": 2,
        "club": 97,
        "email": "aapoorvamodi@gmail.com",
        "phone": "9345635353",
        image: 'https://assets.41clubsofindia.org/national-board/apoorva.jpg'
    },
    {
        "designation": "Covenor RTI Coordinator & Supply House",
        "name": "Kishan Agarwal",
        "area": 6,
        "club": 235,
        "email": "kishan.ggrt@gmail.com",
        "phone": "9864065001",
        image: 'https://assets.41clubsofindia.org/national-board/kishan-agarwal.jpg'
    },
    {
        "designation": "Web Master & Covenor PR",
        "name": "Y V Giri",
        "area": 7,
        "club": 159,
        "email": "giri_68@yahoo.co.uk",
        "phone": "9849004060",
        image: 'https://assets.41clubsofindia.org/national-board/giri.jpg'
    },
    {
        "designation": "Covenor Famit",
        "name": "Vishal Sunda",
        "area": 1,
        "club": 108,
        "email": "vishalcnrt20@gmail.com",
        "phone": "9751018000",
        image: 'https://assets.41clubsofindia.org/national-board/vishal-sunda.jpg'
    },
    {
        "designation": "Special Invitee",
        "name": "DR Siddharthan",
        "area": 1,
        "club": 32,
        "email": "seehappy@gmail.com",
        "phone": "99949 06090",
        image: 'https://assets.41clubsofindia.org/national-board/siddharthan.jpg'
    }
]




//     [
//     {
//         "designation": "President",
//         "name": "B.K.Singh Deo",
//         "club": 37,
//         "area": 6,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/bk-singh-deo.jpeg'
//     },
//     {
//         "designation": "Immediate Past President",
//         "name": "Pankaj Singh",
//         "club": 89,
//         "area": 3,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/pankaj-singh.jpeg'
//     },
//     {
//         "designation": "Vice President",
//         "name": "Srinivasu Saraswatula",
//         "club": 213,
//         "area": 4,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/srinivasu-saraswatula.jpg'
//     },
//     {
//         "designation": "Secretary",
//         "name": "Punit Sampat",
//         "club": 142,
//         "area": 2,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/punit-sampat.jpg'
//     },
//     {
//         "designation": "Treasurer",
//         "name": "Gopa Kumar",
//         "club": 129,
//         "area": 1,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/gopa-kumar.jpeg'
//     },
//     {
//         "designation": "Editor",
//         "name": "Bhupinder Singh",
//         "club": 160,
//         "area": 6,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/bhupinder-singh.jpeg'
//     },
//     {
//         "designation": "IRO",
//         "name": "Vivek Mata",
//         "club": 89,
//         "area": 3,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/vivek-mata.jpeg'
//     },
//     {
//         "designation": "Chairman Area 1",
//         "name": "Vishal Sunda",
//         "club": 108,
//         "area": 1,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/vishal-sunda.jpeg'
//     },
//     {
//         "designation": "Chairman Area 2",
//         "name": "Vinay Grandhi",
//         "club": 236,
//         "area": 2,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/vinay-grandhi.jpeg'
//     },
//     {
//         "designation": "Chairman Area 3",
//         "name": "Bharateesha Honnali",
//         "club": 173,
//         "area": 3,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/bharateesha-honnali.jpeg'
//     },
//     {
//         "designation": "Chairman Area 4",
//         "name": "Dhanesh Rathod",
//         "club": 104,
//         "area": 4,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/dhanesh-rathod.jpeg'
//     },
//     {
//         "designation": "Chairman Area 5",
//         "name": "Gaurav Airen",
//         "club": 238,
//         "area": 5,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/gaurav-airen.jpeg'
//     },
//     {
//         "designation": "Chairman Area 6",
//         "name": "Gopi Krishna More",
//         "club": 131,
//         "area": 6,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/gopi-krishna-more.jpeg'
//     },
//     {
//         "designation": "Chairman Area 7",
//         "name": "Mukund G",
//         "club": 178,
//         "area": 7,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/mukund-g.jpeg'
//     },
//     {
//         "designation": "Chairman Area 8",
//         "name": "Jabyson Abraham Philip",
//         "club": 204,
//         "area": 8,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/jabyson-philip.jpeg'
//     },
//     {
//         "designation": "Rules Convenor",
//         "name": "Samir Agarwal",
//         "club": 131,
//         "area": 6,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/samir-agarwal.jpeg'
//     },
//     {
//         "designation": "Secretariat Convenor & Supply House",
//         "name": "Ashish Gupta",
//         "club": 1,
//         "area": 2,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/ashish-gupta.jpeg'
//     },
//     {
//         "designation": "Share Your Joy",
//         "name": "Prashanth M. Shah",
//         "club": 116,
//         "area": 3,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/prashanth-shah.jpeg'
//     },
//     {
//         "designation": "RTI Coordinator",
//         "name": "Anjum Arora",
//         "club": 243,
//         "area": 5,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/anjum-arora.jpeg'
//     },
//     {
//         "designation": "Project Crimson",
//         "name": "Amarendra Singh",
//         "club": 5,
//         "area": 5,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/amarendra-singh.jpeg'
//     },
//     {
//         "designation": "41 Insurance Convenor",
//         "name": "Rajeev Swaika",
//         "club": 35,
//         "area": 6,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/rajeev-swaika.jpeg'
//     },
//     {
//         "designation": "Young Ambassador Program (Yaps North)",
//         "name": "Samiir Jain",
//         "club": 190,
//         "area": 5,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/samiir-jain.jpeg'
//     },
//     {
//         "designation": "Young Ambassador Program (Yaps South)",
//         "name": "Madhu Babu",
//         "club": 91,
//         "area": 7,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/madhu-babu.jpeg'
//     },
//     {
//         "designation": "Extension Convenor",
//         "name": "Deepak Manu menda",
//         "club": 169,
//         "area": 7,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/deepak-mendaa.jpeg'
//     },
//     {
//         "designation": "Convenor Fund Raising / Csr Tie up",
//         "name": "Padmashish Prasad",
//         "club": 73,
//         "area": 2,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/padmashish-prasad.jpeg'
//     },
//     {
//         "designation": "Howzatt Convenor",
//         "name": "Zishan Ali",
//         "club": 163,
//         "area": 6,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/zishan-ali.jpeg'
//     },
//     {
//         "designation": "41er BNB Convenor",
//         "name": "Paul Verghese",
//         "club": 101,
//         "area": 1,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/paul-verghese.jpeg'
//     },
//     {
//         "designation": "NAGM Convenor",
//         "name": "Raja Shah",
//         "club": 12,
//         "area": 6,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/raja-shah.jpeg'
//     },
//     {
//         "designation": "Special Invitee",
//         "name": "Dr Manoj kapoor",
//         "club": 50,
//         "area": 4,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/manoj-kapoor.jpeg'
//     },
//     {
//         "designation": "Special Invitee",
//         "name": "R.P.Venkatesh",
//         "club": 117,
//         "area": 8,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/rp-venkatesh.jpeg'
//     },
//     {
//         "designation": "Special Invitee (FTDE & Corp Alliances)",
//         "name": "Paresh Choudhary",
//         "club": 110,
//         "area": 4,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/paresh-choudhary.jpeg'
//     },
//     {
//         "designation": "Special Invitee (Presidents Secreteriat)",
//         "name": "Aanand Khara",
//         "club": 169,
//         "area": 7,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/aanand-khara.jpg'
//     },
//     {
//         "designation": "Special Invitee",
//         "name": "Gopal Chopra",
//         "club": 116,
//         "area": 3,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/gopal-chopra.jpeg'
//     },
//     {
//         "designation": "Special Invitee",
//         "name": "Dr Siddharth",
//         "club": 32,
//         "area": 1,
//         "phone": "",
//         "email": "",
//         image: 'https://assets.41clubsofindia.org/national-board/siddharth.jpeg'
//     }
// ]